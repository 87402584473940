import session from './session.js'

const getCookie = () => {
  const user = session.get()
  if (user) {
    if (JSON.parse(atob(user.token.split('.')[1])).exp > Math.trunc(Date.now() / 1000))
      return user
    else {
      console.error('ERROR: user token saved in session storage is expired.')
      delCookie()
    }
  }
  return null
}

const delCookie = () => {
  session.del()
}

const setCookie = (cookie) => {
  session.set(cookie)
}

const cookieManager = {
  set: setCookie,
  get: getCookie,
  del: delCookie
}

export default cookieManager