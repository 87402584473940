/*const parseToken = (tokenString) => {
  if (tokenString) {
    const token = JSON.parse(atob(tokenString.split('.')[1]))
    if (
      token.hasOwnProperty('sub') &&
      token.hasOwnProperty('law') &&
      token.hasOwnProperty('sbe') &&
      token.hasOwnProperty('frc') &&
      token.hasOwnProperty('fps')
    )
      return token
    else return null
  } else return null
}*/

const validateToken = (tokenString) => {
  if (tokenString) {
    const token = tokenString
    if (
      token.hasOwnProperty('name') &&
      token.hasOwnProperty('surname') &&
      token.hasOwnProperty('email') &&
      token.hasOwnProperty('created_at') &&
      token.hasOwnProperty('token')
    )
      return token
    else return null
  } else return null
}

export default validateToken