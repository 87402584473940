import React from "react"
import lang from '../lang/es'

import Modal from './Modal'

import './LawArticlesPicker.css'
import { getLawPlaylist } from "../actions/law"
import Spinner from "./Spinner"

class LawArticlesPicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: this.props.show,
      playlist: null,
      start: -1,
      end: -1
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps && prevProps.show !== this.props.show) {
      this.setState({ playlist: null })

      if (this.props.show)
        getLawPlaylist(this.props.contentId, this.setPlaylist)
    }
  }

  setPlaylist = (playlist) => {
    this.setState({ playlist, start: -1, end: -1 })
  }

  pick = (index) => {
    let start, end

    if (this.state.start === -1) { // First click after initial setup
      start = index
      end = index
    } else if (this.state.start !== this.state.end) { // Reset state when there is already a range and set new click as intial selection point
      start = index
      end = index
    } else if (index >= this.state.start) { // New click is AFTER current selected item, set new click as new selection startpoint
      start = this.state.start
      end = index
    } else { // New click is BEFORE current selected item, set new click as new selection endpoint
      start = index
      end = this.state.end
    }

    this.setState({ start, end })
  }

  pickIfEnabled = (index) => {
    if (this.state.start === -1 || this.state.start === this.state.end) return this.pick(index)
    else return 
  }

  selectAll = () => this.setState({ start: 0, end: this.state.playlist.length })

  clearSelection = () => this.setState({ start: -1, end: -1 })

  select = () => this.props.selected(this.state.start, this.state.end)

  render = () => (
    <Modal
      show={this.props.show}
      close={this.props.close}
      title={lang.CARDS_ARTICLE_SELECTION_MODAL_TITLE}
    >
      {this.state.playlist &&
        <div className={'lawArticlesPickerContent'}>
          <ul className={'lawArticlesPickerList'}>
            {this.state.playlist.map(
              (track, index) =>
                <li
                  key={index}
                  onClick={() => this.pickIfEnabled(index)}
                  className={'lawArticlesPickerTrack' + (index >= this.state.start && index <= this.state.end ? ' lawArticlesPickerTrackSelected' : this.state.start !== this.state.end ? ' lawArticlesPickerTrackNotSelected' : '')}
                >
                  <div className='lawArticlesPickerTrackTitle'>
                    {track.title}
                  </div>
                  <div className='lawArticlesPickerTrackSubtitle'>
                    {track.subtitle || ''}
                  </div>
                </li>
            )
            }
          </ul>
          <div className='lawArticlesPickerButtonsBar'>
            <button className={`rcl rclSecondary lawArticlesPickerButtonsDeleteAll${this.state.start !== -1 ? ' lawArticlesPickerButtonsDeleteAllActive' : ''}`} onClick={this.clearSelection}>
              {lang.CARDS_ARTICLE_SELECTION_RESET}
            </button>
            {this.state.start !== -1 && this.state.end !== -1 ?
              <button className="rcl" onClick={this.select}>
                {lang.CARDS_ARTICLE_SELECTION_CONTINUE}
              </button>
              :
              <button className="rcl rclSecondaryDark" onClick={this.selectAll}>
                {lang.CARDS_ARTICLE_SELECTION_SELECT_ALL}
              </button>
            }
          </div>
        </div>
      }
      {!this.state.playlist &&
        <div className={'lawArticlesPickerSpinnerContainer'}>
          <Spinner className={'lawArticlesPicker'} />
          <div className={'lawArticlesPickerText'}>{lang.LOADING}</div>
        </div>
      }
    </Modal>
  )
}

export default LawArticlesPicker