import React from "react"
import confetti from "canvas-confetti"

import { ReactComponent as SkipIcon } from '../assets/debug-step-over.svg'
import { ReactComponent as DownvoteIcon } from '../assets/thumb-down-outline.svg'
import { ReactComponent as HelpIcon } from '../assets/help-circle-outline.svg'

import './Card.css'
import lang from "../lang/es"

class Card extends React.Component {
  constructor(props) {
    super(props)

    // Generate React references for inputs
    const inputRefs = this.props.card.text.split(/(?=,|:)| /g).filter((word) =>
      this.props.card.keywords.some((k) => k.toLowerCase().trim() === word.toLowerCase().trim())
    ).map(() => React.createRef())

    this.state = {
      inputRefs,
      solved: false
    }
  }

  handleInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      resolve: false,
      solved: false
    })
  }

  resizableInput = (name, answer, ref) => {
    let resolveClass = ''

    const solved = this.state[name] &&
      this.state[name].toLowerCase().trim() === answer.toLowerCase().trim()

    if (this.state.resolve) {
      resolveClass = solved ? ' cardsCardInputResolvedOk' : ' cardsCardInputResolvedKo'
    }

    return <span className='cardsCardInputWrapper'>
      <input
        ref={ref}
        value={this.state[name] ? this.state[name] : ''}
        name={name}
        answer={answer}
        solved={solved ? '1' : '0'}
        type='text'
        maxLength='30'
        className={`cardsCardInput${resolveClass}`}
        onChange={this.handleInput}
        disabled={this.state.solved}
      />
      <button type="button" className={'cardsCardButtonResolve'} onClick={() => this.cheat(ref)} tabIndex="-1">
        <HelpIcon className={'cardsCardIcon'} title={lang.CARDS_CARD_RESOLVE} />
      </button>
      <span className='cardsCardInputHelper'>
        {this.state[name] ? this.state[name] : ''}
      </span>
    </span>
  }

  cheat = (ref) => {
    const input = ref.current
    this.setState({ [input.getAttribute('name')]: input.getAttribute('answer'), resolve: false })
  }

  cardText = (text, keywords) => {
    let inputs = 0
    const fragments = text.split(/(?=,|:)| /g).map((word, i) => {
      if (keywords.some((k) => k.toLowerCase().trim() === word.toLowerCase().trim())) {
        const resInput = this.resizableInput(i, word, this.state.inputRefs[inputs])
        inputs++
        return resInput
      }
      return `${word}`
    })

    return fragments.map((f, i) =>
      <React.Fragment key={i}>
        {f}{i < fragments.length - 1 ? ' ' : ''}
      </React.Fragment>
    )
  }

  resolve = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const domRect = event.target.getBoundingClientRect()
    const yPos = (domRect.y + domRect.height) / window.innerHeight
    
    const solved = this.state.inputRefs.every((ref) => ref.current.getAttribute('solved') === '1')

    this.setState({
      solved,
      resolve: true
    })

    if (solved) {
      confetti({
        particleCount: 10,
        spread: 90,
        gravity: 1,
        angle: 90,
        startVelocity: 45,
        ticks: 50,
        scalar: 2,
        disableForReducedMotion: true,
        origin: { y: yPos }
      })
    }
  }

  next = () => this.props.resolve()

  render = () => (
    <form onSubmit={this.resolve} className={`cardsCardContainer ${this.props.darkMode ? 'darkModeBackground' : ''}`}>
      <div className='cardsCardText'>
        {this.cardText(this.props.card.text, this.props.card.keywords)}.
      </div>
      <div className='cardsCardOrigin'>
        {this.props.card.articleName}
      </div>
      <div className="cardsCardButtons">
        <button  type="button" className={'cardsCardButtonIcon'} onClick={this.props.downvote}>
          <DownvoteIcon className={'cardsCardIcon'} title={lang.CARDS_CARD_DOWNVOTE} />
        </button>
        {this.state.solved ?
          <button  type="button" className={'rcl'} onClick={this.next}>
            {lang.CARDS_CARD_NEXT}
          </button>
          :
          <button type="submit" className={'rcl cardsCardButton'}>
            {lang.CARDS_CARD_REVIEW}
          </button>
        }
        <button
          className={`cardsCardButtonIcon${this.state.solved ? ' cardsCardButtonHidden' : ''}`}
          type="button"
          disabled={this.state.solved}
          onClick={this.props.skip}
        >
          <SkipIcon className={'cardsCardIcon'} title={lang.CARDS_CARD_SKIP} />
        </button>
      </div>
    </form>
  )
}

export default Card