import React from "react"

import { ReactComponent as Notebook } from '../assets/notebook-outline.svg'
import { ReactComponent as LawIcon } from '../assets/scale-balance.svg'
import { ReactComponent as ExitIcon } from '../assets/exit-run.svg'
import { ReactComponent as AccountIcon } from "../assets/account-circle.svg"
//import {ReactComponent as SubscriptionIcon} from "../assets/calendar-clock.svg"
import { ReactComponent as SwitchThemeIcon } from "../assets/brightness-4.svg"
import { ReactComponent as HelpIcon } from "../assets/help-circle.svg"
import { ReactComponent as CardsIcon } from '../assets/cards-playing-outline.svg'

import './Menu.css'
import lang from '../lang/es'

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      purchasedLaws: {},
      showLawPurchaser: false
    }
  }

  setDiscType = (type) => {
    this.props.setDiscType(type)
    this.props.toggleMenu()
  }

  logout = () => {
    this.props.setConfirm(lang.MENU_LOGOUT_CONFIRM, (res) => { if (res) this.props.delAuthToken() })
  }

  render = () => (
    <div className={'menuBackground' + (this.props.show ? ' showMenu menuBackgroundShown' : ' hideMenu menuBackgroundHidden')}
      onClick={this.props.toggleMenu}
    >
      <div className={'menuContainer ' + (this.props.show ? 'showMenu' : 'hideMenu')} onClick={(e) => e.stopPropagation()}>
        <ul className='menuButtonList'>
          <li className='section'>
            {lang.MENU_AUDIO_SECTION}
          </li>
          <li className={'menuButtonListItem'} onClick={() => this.setDiscType('custom')}>
            <Notebook className={'sectionIcon'} />
            {lang.MENU_NOTEBOOK_LABEL}
          </li>
          <li className={'menuButtonListItem'} onClick={() => this.setDiscType('law')}>
            <LawIcon className={'sectionIcon'} />
            {lang.MENU_PURCHASED_LAW_LIST}
          </li>
          <li className='section'>
            {lang.MENU_VISUAL_SECTION}
          </li>
          <li className={'menuButtonListItem'} onClick={() => this.setDiscType('cards')}>
            <CardsIcon className={'sectionIcon'} />
            {lang.MENU_CARDS_GAME}
          </li>
          {/* Subscription section disabled temporarily
          <li className={'menuButtonListItem'} onClick={this.props.toggleSubscription}>
            <SubscriptionIcon className={'sectionIcon'}/>
            { lang.MENU_SUBSCRIPTION }
          </li>
          */}
          <li className={'menuButtonListItem menuButtonListItemBottom'} onClick={this.props.toggleDarkMode}>
            <SwitchThemeIcon className={'sectionIcon'} />
            {lang.MENU_DARK_MODE}
          </li>
          <li className={'menuButtonListItem'} onClick={this.props.toggleAccount}>
            <AccountIcon className={'sectionIcon'} />
            {lang.MENU_MY_ACCOUNT}
          </li>
          <li className={'menuButtonListItem'} onClick={this.props.toggleContact}>
            <HelpIcon className={'sectionIcon'} />
            {lang.MENU_CONTACT}
          </li>
          <li className={'menuButtonListItem'} onClick={this.logout}>
            <ExitIcon className={'sectionIcon'} />
            {lang.MENU_LOGOUT}
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Menu