import config from "../config"
import renewSession from "../actions/auth"

class SessionRenovator {
  timer = null

  renew = (cb) => renewSession(cb)

  start = (cb) => {
    this.timer = setTimeout(() => this.renew(cb), config.TOKEN_AUTO_RENEW_INTERVAL)
  }
  stop = () => {
    clearTimeout(this.timer)
  }
}

export default SessionRenovator