import React from "react"
import { ReactComponent as Logo } from '../assets/logo.svg'
import { ReactComponent as PlaylistLogo } from '../assets/playlist_icon_v2.svg'

import './NavBar.css'

class NavBar extends React.Component {
  render = () => (
    <div id={'navBar'}>
      <div
        className={this.props.showMenu ? 'navBarMenuToggle navBarMenuShow' : 'navBarMenuToggle'}
        onClick={this.props.toggleMenu}
      >
        <div className="navBarMenuButtonBar1"/>
        <div className="navBarMenuButtonBar2"/>
        <div className="navBarMenuButtonBar3"/>
      </div>
      <Logo className='navBarAppLogo'/>
      <PlaylistLogo
        className={'navBarPlaylistLogo' + (this.props.showPlaylist ? ' navBarPlaylistLogoActive' : '')  + (!this.props.showPlaylistButton ? ' navBarPlaylistLogoHidden' : '')}
        onClick={() => this.props.togglePlaylist(!this.props.showPlaylist)}
      />
    </div>
  )
}

export default NavBar