import config from '../config.js'

const lang = {
  LOADING: "Cargando...",

  PLACEHOLDER_ARTICLE_TITLE: "¡Bienvenido a Recitaleyes!",
  PLACEHOLDER_ARTICLE_TEXT: "Abre el menú de arriba a la izquierda para comenzar.",
  PLAYLIST_LAW_PLACEHOLDER: "No has seleccionado contenido para lista de reproducción",
  PLAYLIST_LAW_ADD_LAW_BUTTON: "Añadir leyes",
  PLAYLIST_LAW_BACK_BUTTON: "Volver",
  PLAYLIST_LAW_REMAINING: "Te quedan %1 leyes por elegir",

  LAW_SELECTOR_LABEL_CONSOLIDATED: "Actualizada según la última versión oficial consolidada, de",
  LAW_SELECTOR_LABEL_UPDATED: "Actualizada el %1 según la última versión oficial",

  PLAYLIST_CUSTOM_CONTENT_PLACEHOLDER_ICON_TEXT: "¡Vaya!",
  PLAYLIST_CUSTOM_CONTENT_PLACEHOLDER_MESSAGE: "Todavía no has subido tus apuntes a Recitaleyes.",
  PLAYLIST_CUSTOM_CONTENT_PLACEHOLDER_CALL_TO_ACTION: "Haz click en añadir para empezar.",
  PLAYLIST_CUSTOM_CONTENT_PLACEHOLDER_ADD_BUTTON: "Añadir",
  PLAYLIST_CUSTOM_CONTENT_PLACEHOLDER_TTS: "Generar audios",
  //PLAYLIST_CUSTOM_CONTENT_TRACK_SAVED: "Genera el audio por %1 página(s)",
  PLAYLIST_CUSTOM_CONTENT_TRACK_QUEUED: "Generando audio...",
  PLAYLIST_CUSTOM_CONTENT_ADD_BUTTON: "Añadir apuntes",
  PLAYLIST_CUSTOM_CONTENT_TTS_CONFIRM: "¿Quieres generar el audio de estos apuntes?",
  PLAYLIST_CUSTOM_CONTENT_TTS_PURCHASE_OK: "¡Listo! El audio de tus apuntes estará disponible en breve.",
  PLAYLIST_CUSTOM_CONTENT_TTS_PURCHASE_ERROR: "¡Vaya! Se ha producido un error. Vuelve a intentarlo o ponte en contacto con soporte.",
  PLAYLIST_CUSTOM_CONTENT_TTS_WORKING: "¡Ya casi! El audio de tus apuntes se está generando en nuestros servidores. Este proceso puede tardar hasta 24 horas.",
  PLAYLIST_CUSTOM_CONTENT_NO_TTS_PURCHASED: "¡Todavía no has generado el audio de estos apuntes! Para hacerlo, haz click aquí.",
  PLAYLIST_CUSTOM_CONTENT_DELETE_CONFIRM: "¿Seguro que quieres borrar estos apuntes?",
  PLAYLIST_CUSTOM_CONTENT_DELETE: "El contenido ha sido borrado",
  PLAYLIST_CUSTOM_CONTENT_DELETE_ERROR: "¡Vaya! No hemos podido borrar tus apuntes. Inténtalo de nuevo o contacta con soporte.",
  //PLAYLIST_CUSTOM_REMAINING: "Tienes %1 página(s) disponibles en tu libreta",
  PLAYLIST_CUSTOM_NOT_ENOUGH_CREDITS: "¡Vaya! Para generar el audio de estos apuntes necesitas más páginas. ¿Quieres añadir páginas a tu libreta de apuntes de Recitaleyes?",
  PLAYLIST_CUSTOM_MENU_GENERATE: "Generar audio",
  PLAYLIST_CUSTOM_MENU_GENERATING: "Generando audio...",
  PLAYLIST_CUSTOM_MENU_EDIT: "Editar",
  PLAYLIST_CUSTOM_MENU_DELETE: "Borrar",
  PLAYLIST_CUSTOM_PLAYLIST_READY_LABEL: "Tus apuntes",
  PLAYLIST_CUSTOM_PLAYLIST_READY_QUEUED: "Audio en preparación",
  PLAYLIST_CUSTOM_PLAYLIST_READY_OTHER: "Sin audio",

  CUSTOM_CONTENT_EDITOR_TITLE_PLACEHOLDER: "Título",
  CUSTOM_CONTENT_EDITOR_TITLE_MISSING: "¡Oops! Debes indicar un título para este tema.",
  CUSTOM_CONTENT_EDITOR_SAVE: "Guardar",
  CUSTOM_CONTENT_EDITOR_CANCEL: "Cancelar",
  CUSTOM_CONTENT_EDITOR_PDF_CHANGE: "Cambiar archivo",
  CUSTOM_CONTENT_EDITOR_SAVE_ERROR: "¡Vaya! Se ha producido un error al guardar tus apuntes. Inténtalo de nuevo o ponte en contacto con el servicio de soporte.",
  CUSTOM_CONTENT_EDITOR_SAVE_SUCCESS: "¡Listo! Tus apuntes se han guardado correctamente.",
  CUSTOM_CONTENT_EDITOR_INFO: "¡Ojo con las siglas y las abreviaturas! Recuerda que se leerán tal cual, así que escríbelas como quieres escucharlas! ;).",
  CUSTOM_CONTENT_EDITOR_UPLOAD_FILE_PDF_BUTTON: 'Subir apuntes en PDF (.pdf)',
  CUSTOM_CONTENT_EDITOR_PLACEHOLDER:
`<html style="font-family: Montserrat, sans-serif;">
  <p>¡Hola!</p>
  <p>Desde aquí podrás añadir a Recitaleyes tus propios apuntes para escucharlos y leerlos estés donde estés.</p>
  <p>Puedes hacerlo de dos maneras:</p>
  <ol>
    <li>
      <span style="text-decoration: underline;"><strong>Subiendo un archivo PDF</strong></span>:
        pulsa en el botón "Subir apuntes en PDF (.pdf)" que encontrarás en el menú superior y selecciona el archivo que deseas subir.
    </li>
    <li>
      <span style="text-decoration: underline;"><strong>Escribiendo aquí directamente</strong></span>:
        copia y pega aquí tus apuntes o escribe en este cuadro de texto directamente.
    </li>
  </ol>
  <p>&nbsp;</p>
  <p><em><span style="text-decoration: underline;">¡Así de fácil!</span></em></p>
  <p>&nbsp;</p>
  <p>Un par de consejos para mejorar la lectura de tus apuntes con Recitaleyes:</p>
  <ul>
  <li>Recitaleyes leerá tus apuntes tal cual están en tus apuntes, con lo que es posible que no lea correctamente las abreviaturas.</li>
  <li>Las imágenes y las tablas se mostrarán, pero Recitaleyes no podrá leerlas.</li>
  <li>La subida de archivos PDF no es compatible con todos los tipos de PDF, pero sí con la gran mayoría. ¡Prueba y, si no funciona, escríbenos!</li>
  <li>Si nos falta alguna norma en Recitaleyes, no es necesario que la subas como apuntes, ¡contacta con nosotros y la añadiremos!</li>
  </ul>
  <p>&nbsp;</p>
</html>`,
  
  CUSTOM_CONTENT_EDITOR_FILE_PICKER_TEXT: 'Elige un archivo PDF para añadir a tus apuntes',

  CONFIRM_YES: "Sí",
  CONFIRM_NO: "No",

  PLAYER_REPORT_BUTTON_TEXT: "Reportar un error",
  PLAYER_REPORT_BUTTON_SENDING: "Enviando...",
  PLAYER_REPORT_BUTTON_DONE: "¡Gracias! Revisaremos el texto y audio de este artículo",
  PLAYER_REPORT_BUTTON_ERROR: "Se ha producido un error durante el reporte. Inténtalo de nuevo.",
  PLAYER_REPORT_BUTTON_SUBJECT: "article-report",
  PLAYER_REPORT_CONFIRM: "¿Has encontrado un error en este artículo y deseas reportarlo para su revisión?",

  MENU_NOTEBOOK_LABEL: "Mis apuntes",
  MENU_PURCHASED_LAW_LIST: "Mis leyes",
  MENU_CARDS_GAME: "Tarjetas",
  MENU_SUBSCRIPTION: "Mi suscripción",
  MENU_CHANGE_PASSWORD: "Cambiar contraseña",
  MENU_MY_ACCOUNT: "Mi cuenta",
  MENU_DARK_MODE: "Modo claro/oscuro",
  MENU_CONTACT: "Ayuda",
  MENU_LOGOUT: "Salir",
  MENU_LOGOUT_CONFIRM: "¿Seguro que quieres salir de Recitaleyes?",
  MENU_AUDIO_SECTION: "Audio",
  MENU_VISUAL_SECTION: "Visual",

  BACK_BUTTON: "Volver",
  CANCEL_AND_BACK_BUTTON: "Cancelar y volver",

  MY_ACCOUNT_TITLE: "Mi cuenta",
  MY_ACCOUNT_EMAIL: "Correo electrónico:",
  MY_ACCOUNT_CHANGE_PASSWORD: "Cambiar contraseña",
  MY_ACCOUNT_DELETE_ACCOUNT: "Borrar mi cuenta",
  MY_ACCOUNT_DELETE_ACCOUNT_CONFIRMATION: "¿Estás seguro de que quieres borrar tu cuenta? Perderás todos tus apuntes y leyes. Esta acción no se puede deshacer.",
  MY_ACCOUNT_DELETE_ACCOUNT_REQUESTING: "Solicitando el borrado de tu cuenta...",
  MY_ACCOUNT_DELETE_ACCOUNT_REQUESTED: "Se ha solicitado el borrado de tu cuenta. En 24h recibirás un email de confirmación.",
  MY_ACCOUNT_DELETE_ACCOUNT_REQUEST_ERROR: `Se ha producido un error solicitado el borrado de tu cuenta. Inténtalo de nuevo o ponte en contacto con nosotros a través del botón de ayuda o en ${config.CONTACT_EMAIL}.`,

  HINT_NAME: "Nombre",
  HINT_SURNAME: "Apellidos",
  HINT_EMAIL: "Correo electrónico",
  HINT_PASSWORD: "Contraseña",
  HINT_NEW_PASSWORD: "Nueva contraseña",
  HINT_PASSWORD_CONFIRM: "Repite tu contraseña",
  HINT_NEW_PASSWORD_CONFIRM: "Repite tu nueva contraseña",

  LOGIN_BUTTON: "Acceder",
  LOGIN_SIGNUP: "¿Aún no tienes una cuenta? Regístrate gratis",
  LOGIN_ERROR: "El email o la contraseña introducidos no son correctos.",
  LOGIN_RESET_PASSWORD: "¿Has olvidado tu contraseña?",
  LOGGING_IN: "Accediendo...",

  SIGNUP_BUTTON: "Registarse",
  SIGNUP_LOADING: "Registrándote...",
  SIGNUP_ALREADY_REGISTERED: "¿Ya tienes una cuenta creada?",
  SIGNUP_ERROR_NAME: "¡Oops! Te falta introducir tu nombre",
  SIGNUP_ERROR_SURNAME: "¡Oops! Te falta introducir tus apellidos",
  SIGNUP_ERROR_EMAIL: "¡Vaya! Parece que no has introducido correctamente tu dirección de email",
  SIGNUP_ERROR: "¡Vaya! Se ha producido un error... ¿Seguro que no te has registrado ya antes?",
  SIGNUP_SUCCESS: "¡Listo! Te hemos enviado un correo electrónico a \"%1\" con un enlace para que actives tu cuenta y puedas empezar a escuchar tus leyes y apuntes.",

  PASSWORD_RESTORE_BUTTON: "Restaurar contraseña",
  PASSWORD_RESTORE_LOADING: "Restaurando contraseña...",
  PASSWORD_RESTORE_ERROR: "Se ha producido un error al restaurar tu contraseña. Comprueba que hayas escrito correctamente tu email o ponte en contacto con el servicio de soporte.",
  PASSWORD_RESTORE_SUCCESS: "¡Listo! Si la dirección de correo electrónico es correcta, se te habrá enviado un correo electrónico con un enlace para que establezcas una nueva contraseña. Si en 10 minutos no te ha llegado el email, ponte en contacto con el servicio de soporte.",

  UPDATE_PASSWORD_BUTTON: "Crear nueva contraseña",
  UPDATE_PASSWORD_LOADING: "Creando contraseña...",
  UPDATE_PASSWORD_ERROR_CHECK_FAILED: "Las contraseñas introducidas son diferentes.",
  UPDATE_PASSWORD_ERROR_LENGTH: "La contraseña debe tener, al menos, " + config.MIN_PASS_LENGTH + " caracteres.",
  UPDATE_PASSWORD_EXPIRED_LINK_ERROR: "El enlace de creación de contraseña ha caducado o ya lo has utilizado anteriormente.",
  UPDATE_PASSWORD_GENERIC_ERROR: "Se ha producido un error al crear tu contraseña. Solicita un nuevo enlace de recuperación de contraseña o ponte en contacto con el servicio de soporte.",
  UPDATE_PASSWORD_SUCCESS: "¡Listo! Ya puedes acceder a Recitaleyes con tu nueva contraseña.",

  iOsBkgEnabled: "Reproducción en segundo plano activada",
  iOsBkgDisabled: "Reproducción en segundo plano desactivada",

  LAW_PURCHASE_MODAL_TITLE: "Selecciona una ley",
  LAW_PURCHASE_FILTER_PLACEHOLDER: "Buscar...",
  LAW_PURCHASE_NO_LAWS_AVAILABLE: "Ya tienes todas las normas disponibles",
  LAW_PURCHASE_ERROR_FROM_SERVER: "Se ha producido un error al seleccionar la ley. Vuelve a intentarlo o ponte en contacto con el servicio de soporte escribiendo a ",
  LAW_PURCHASE_CONFIRM: "¿Quieres activar en tu cuenta de Recitaleyes: %1?",
  LAW_PURCHASE_BUY_MORE: "Ver paquetes",
  LAW_PURCHASE_LOADING_MESSAGE: "Seleccionando...",
  LAW_PURCHASE_DONE_OK: "¡Listo! Ya puedes empezar a escuchar tu nueva norma.",
  LAW_PURCHASE_DONE_CLOSE: "Volver",

  LEGAL_LOPD_FIRST_LAYER_CONTACT: "Estos datos solo serán utilizados por Recitaleyes para atender tu consulta. Conoce tus derechos en nuestra ",
  LEGAL_LOPD_FIRST_LAYER: 'Los datos que nos proporciones serán utilizados por Recitaleyes únicamente para prestarte los servicios solicitados. Conoce tus derechos en nuestra ',
  LEGAL_TOS_FIRST_LAYER: " Al utilizar nuestros servicios, aceptas las ",
  LEGAL_LOPD: "política de privacidad",
  LEGAL_TOS: "condiciones generales",

  CART_BUY_CREDITS: "Créditos para convertir apuntes en audio",
  CART_BUY_LAW: "Más leyes",
  CART_UNTIL: "Hasta",
  CART_TITLES: {
    TypeSelector: "Selecciona qué quieres añadir a tu cuenta de Recitaleyes",
    LawPackSelector: "¿Cuántas leyes necesitarás?",
    CreditsSelector: "¿Cuántos créditos quieres?",
    FinalCart: "Tu compra",
    PurchaseDone: "¡Compra realizada!"
  },
  CART_CUSTOM_MISSING: "Añade %1 páginas más y podrás generar el audio de todos los apuntes que ya tienes subidos a Recitaleyes.",
  CART_CUSTOM_TO_CHECKOUT: "Continuar",
  CART_CUSTOM_CREDITS_LABEL: "créditos",
  CART_CUSTOM_HINT: "(unas %1 pág. de apuntes)",
  CART_TOTAL: "Total",
  CART_CHECKOUT_ERROR: `¡Vaya! Se ha producido un error con la pasarela de pago. Si se te ha cobrado y no ves tu compra realizada en unos minutos, escríbenos a ${config.CONTACT_EMAIL}.`,
  CART_CHECKOUT_COMPLETE_FAST_SUCCESS: "¡Listo! Tu compra se ha realizado correctamente y ya está activa en tu cuenta.",
  CART_CHECKOUT_COMPLETE_FAST_ERROR: `¡Vaya! Tu compra está tardando un poco más de lo habitual en activarse. Si en 15 minutos no la ves activa en tu cuenta, escríbenos a ${config.CONTACT_EMAIL} y te la activaremos manualmente.`,

  SUBSCRIPTION_TITLE: "Mi suscripción",
  SUBSCRIPTION_TIME_SECTION: "Mi suscripción a Recitaleyes",
  SUBSCRIPTION_NO_SUBSCRIPTION_EVER: "¡Vaya! Todavía no tienes ninguna suscripción activa :(",
  SUBSCRIPTION_REMAINING: "Tu suscripción está ACTIVA y caduca el %1 (en %2 días)",
  SUBSCRIPTION_EXPIRED: "¡Vaya! Tu suscripción ha caducado el %1 (hace %2 días)",
  SUBSCRIPTION_AWS_EDSTART_PROMO: "Tu suscripción está ACTIVA y sin coste gracias al programa AWS EdStart",
  SUBSCRIPTION_SUBSCRIPTION_SUBSCRIBE: "Añadir suscripción",
  SUBSCRIPTION_SUBSCRIPTION_ADD_MORE: "Ampliar suscripción",
  SUBSCRIPTION_CUSTOM_BOOK: "Mi libreta de apuntes",
  SUBSCRIPTION_CUSTOM_BOOK_NO_CREDITS: "¡Vaya! No te quedan páginas disponibles para crear o modificar tus apuntes",
  SUBSCRIPTION_CUSTOM_CREDITS_AVAILABLE: "Te quedan %1 páginas disponibles para crear o modificar tus apuntes",
  SUBSCRIPTION_CUSTOM_SUBSCRIBE: "Añadir más páginas",
  SUBSCRIPTION_CUSTOM_REQUIREMENTS: "Para escuchar tus apuntes, necesitas una suscripción a Recitaleyes activa",
  SUBSCRIPTION_VOUCHER: "¿Tienes un cupón?",
  SUBSCRIPTION_FEATURES: {
    "Incluye:": [
      'Lee y escucha tus propios apuntes',
      'Lee y escucha todas nuestras leyes',
      'Leyes siempre actualizadas',
      'Acceso desde cualquier lugar',
      'Compatible con ordenador, móvil y tablet',
    ]
  },
  CREDITS_FEATURES: {
    "Con las páginas, podrás:": [
      'Generar el audio de tus propios apuntes',
      'Añadir nuevos temas a tus propios apuntes',
      'Modificar tus propios apuntes',
      'Actualizar tus propios apuntes',
      'Las páginas no caducan, quedan asociadas a tu cuenta para siempre',
    ]
  },
  SUBSCRIPTION_TIME_PICKER_TITLE: 'Elige la duración de tu suscripción',
  SUBSCRIPTION_TIME_PICKER_UNIQUE_PAYMENT: 'Pago único de',
  SUBSCRIPTION_CREDITS_PURCHASE_TITLE: '¿Cuántas páginas necesitas?',
  SUBSCRIPTION_CREDITS_PAGES: 'páginas',
  SUBSCRIPTION_CREDITS_CHECKOUT_NAME: 'páginas para tus apuntes',
  SUBSCRIPTION_CREDITS_CHECKOUT_ERROR_NO_SUBSCRIPTION: "¡Vaya! Actualmente no tienes una suscripción activa a Recitaleyes. Para utilizar tu y escuchar tus apuntes, necesitas una suscripción.",

  SUBSCRIPTION_CHECKOUT_TITLE: 'Tu compra',

  SUBSCRIPTION_COUPON_INPUT_TITLE: 'Introduce tu cupón o código de acceso',
  SUBSCRIPTION_COUPON_INPUT_HINT: 'Cupón o código',
  SUBSCRIPTION_COUPON_INPUT_CANCEL: 'Cancelar',
  SUBSCRIPTION_COUPON_INPUT_ACTIVATE: 'Activar',
  SUBSCRIPTION_COUPON_REDEEMING_MODAL_TITLE: 'Cupón o código de acceso',
  SUBSCRIPTION_COUPON_REDEEMING_LOADING_TEXT: 'Activando tu cupón...',
  SUBSCRIPTION_COUPON_REDEEMING_SUCCESS: '¡Listo! El cupón "%1" ha sido activado correctamente en tu cuenta.',
  SUBSCRIPTION_COUPON_REDEEMING_ERROR: `¡Vaya! No se ha podido activar tu cupón. Si el cupón es válido, ponte en contacto con soporte a través del botón de Ayuda.`,

  NO_SUBSCRIPTION_PLAYER_ERROR: 'Para reproducir este contenido necesitas una suscripción a Recitaleyes. ¡Haz click aquí para suscribirte!',

  TOOLTIP_PAGE_KEYWORD: 'páginas',
  TOOLTIP_PAGE_EXPLANATION: 'Una página equivale a 3.000 caracteres (letras, espacios, números, etc.)',

  FILE_PICKER_UPLOAD: 'Continuar',
  FILE_PICKER_WRONG_FORMAT: 'El archivo debe ser un documento PDF',
  FILE_PICKER_TOO_BIG: '¡Vaya! El archivo seleccionado es demasiado grande. Selecciona uno menor de  %1mb.',

  CARDS_HEADER: '¡Completa las tarjetas!',
  CARDS_DESCRIPTION: 'Refuerza tu aprendizaje con nuestro juego de autocompletar tarjetas. Practica tus leyes y apuntes rellenando los espacios en blanco y mejora tu aprendizaje de forma interactiva y efectiva.',
  CARDS_PICK_LAW: 'Leyes',
  CARDS_PICK_CUSTOM: 'Apuntes',
  CARDS_LOADING: 'Generando tarjetas...',
  CARDS_CARD_REVIEW: 'Corregir',
  CARDS_CARD_NEXT: '¡Siguiente!',
  CARDS_CARD_SKIP: 'Omitir tarjeta',
  CARDS_CARD_RESOLVE: 'Resolver',
  CARDS_CARD_DOWNVOTE: 'Reportar tarjeta',
  CARDS_CUSTOM_NOT_AVAILABLE: "¡Oops! El juego de las tarjetas todavía no está disponible... ¡Pero pronto lo estará!",
  CARDS_DISCLAIMER: "Nuestra IA de creación de tarjetas está en fase BETA. Puede haber fallos en la interfaz o en la calidad de los fragmentos, ¡pero te garantizamos que el contenido es 100% fiel a la ley!",
  CARDS_PLAY_AGAIN: 'Repetir',
  CARDS_CHANGE_CONTENT: 'Inicio',
  CARDS_DOWNVOTE_CONFIRM: '¿Quieres reportar esta tarjeta como incorrecta para su revisión?',
  CARDS_NOT_ENOUGH_CARDS_TOAST: '¡Vaya! Parece que no se han podido generar cartas del rango que has seleccionado. ¡Prueba a elegir un rango más amplio!',

  CARDS_END_TITLE: "¡Has llegado al final!",
  CARDS_END_CONTENT: "Pulsa para generar nuevas tarjetas y volver a jugar",

  CARDS_ARTICLE_SELECTION_MODAL_TITLE: "¿De qué rango de artículos quieres generar tarjetas?",
  CARDS_ARTICLE_SELECTION_SELECT_ALL: "Seleccionar todo",
  CARDS_ARTICLE_SELECTION_CONTINUE: "Continuar",
  CARDS_ARTICLE_SELECTION_RESET: "Borrar selección",

  BACK: 'Volver',
  CURRENCY: '€',
  MONTH: 'mes',
  MONTHS: ["enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre"]
}

export default lang