import config from '../config.js'

const getAuthCookie = (user, pass, cb) => {
  const opts = {
    method: 'post',
    body: JSON.stringify({ user, pass })
  }

  fetch(`${config.HTTP_PROTOCOL}://${config.RECITALEYES_LOGIN_URL}/signIn`, opts)
    .then((res) => {
      if (res.ok) res.text().then(token => {
        cb(token)
      })
      else cb(null)
    })
    .catch(() => cb(null))
}



export default getAuthCookie