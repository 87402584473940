import config from "../config"
import session from "../utils/session"

const renewSession = (cb) => {
  fetch(
    `${config.HTTP_PROTOCOL}://${config.RECITALEYES_LOGIN_URL}/renewSession`,
    {
      headers: { "Authorization": `Bearer ${session.get().token}` }
    }
  ).then((res) => {
    if (res.ok) res.text().then(token => {
      cb(token)
    })
    else cb(null)
  })
    .catch(() => cb(null))
}

export default renewSession