import React from "react"
import { toast } from "react-toastify"
import confetti from "canvas-confetti"

import ListLaws from "./ListLaws.js"
import Spinner from "./Spinner.js"
import { getCards, reportCard } from "../actions/cards.js"
import Card from "./Card.js"

import lang from '../lang/es.js'
import './Cards.css'
import LawArticlesPicker from "./LawArticlesPicker.js"

class Cards extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: 0,
      pickLaw: false,
      pickTracks: false,
      contentId: null,
      contentType: null,
      cards: null,
      currentCard: 0,
      trackStart: -1,
      trackEnd: -1
    }
  }

  componentDidMount = () => this.init()

  init = () => {
    setTimeout(() => this.setState({ loaded: 1 }), 250)
  }

  switchLawPicker = () => this.setState({ pickLaw: !this.state.pickLaw })
  switchTrackPicker = () => this.setState({ pickTracks: !this.state.pickTracks })
  switchCustomPicker = () => {
    toast.warn(lang.CARDS_CUSTOM_NOT_AVAILABLE, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }

  setContent = (contentType, contentId, trackStart = -1, trackEnd = -1) => {
    if (contentType === 'law') {
      this.setState({
        pickLaw: false,
        pickTracks: true,
        contentType,
        contentId,
        trackStart,
        trackEnd
      })
    } else { // Custom documents does not contain tracks
      this.callForCards(
        contentType,
        contentId
      )
    }
  }

  setLawCards = (trackStart, trackEnd) => {
    this.callForCards(
      this.state.contentType,
      this.state.contentId,
      trackStart,
      trackEnd
    )
  }

  callForCards = (contentType, contentId, trackStart = -1, trackEnd = -1) => {
    getCards(
      contentType,
      contentId,
      trackStart,
      trackEnd,
      (cards) => this.setCards(cards, trackStart, trackEnd)
    )
  }

  setCards = (cards, trackStart, trackEnd) => {
    // TODO if no cards => alert and show picker again
    if (cards.length === 0) {
      toast.info(lang.CARDS_NOT_ENOUGH_CARDS_TOAST, {
        position: "top-center",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })

      this.playAgain()
    } else {
      this.setState({ cards, pickTracks: false, trackStart, trackEnd })
    }
  }

  intro = () => {
    return (
      <div id={'cardsContent'}
        style={{ opacity: this.state.loaded }}
        className={`${this.props.darkMode ? 'darkMode' : ''}`}
      >
        <h1>{lang.CARDS_HEADER}</h1>
        <div>{lang.CARDS_DESCRIPTION}</div>
        <div className='openPickerContainer'>
          <button className={'rcl openPickerButton'} onClick={this.switchLawPicker}>
            {lang.CARDS_PICK_LAW}
          </button>
          <button className={'rcl rclSecondaryDark openPickerButton'} onClick={this.switchCustomPicker}>
            {lang.CARDS_PICK_CUSTOM}
          </button>
        </div>
        <div className='cardsDisclaimer'>
          {lang.CARDS_DISCLAIMER}
        </div>
        <ListLaws
          show={this.state.pickLaw}
          close={this.switchLawPicker}
          toggleContact={this.props.toggleContact}
          selected={(lawId) => this.setContent('law', lawId)}
        />
        <LawArticlesPicker
          show={this.state.pickTracks}
          close={this.reset}
          contentId={this.state.contentId}
          selected={(trackStart, trackEnd) => this.setLawCards(trackStart, trackEnd)}
        />
      </div>
    )
  }

  loading = () => {
    return (
      <div className='cardsLoading'>
        <Spinner className='cardsLoadingSpinner' />
        <div>{lang.CARDS_LOADING}</div>
      </div>
    )
  }

  skipCard = () => this.setState({ currentCard: this.state.currentCard + 1, discardType: 'cardSkip' })
  resolveCard = () => this.setState({ currentCard: this.state.currentCard + 1, discardType: 'cardResolving' })
  downvoteCard = () => this.props.setConfirm(lang.CARDS_DOWNVOTE_CONFIRM, (r) => { if (r) this.downvoteConfirmed() })

  downvoteConfirmed = () => {
    reportCard(this.state.contentType, this.state.contentId, this.state.cards[this.state.currentCard], (r) => console.log(r))
    this.setState({ currentCard: this.state.currentCard + 1, discardType: 'cardDestroy' })
  }

  reset = () => {
    this.setState(
      {
        loaded: 0,
        pickLaw: false,
        pickTracks: false,
        contentId: null,
        contentType: null,
        cards: null,
        currentCard: 0,
        trackStart: -1,
        trackEnd: -1
      })

    setTimeout(this.init, 250)
  }

  playAgain = () => {
    const contentType = this.state.contentType
    const contentId = this.state.contentId
    const trackStart = this.state.trackStart
    const trackEnd = this.state.trackEnd
    this.reset()
    setTimeout(() => this.setContent(contentType, contentId, trackStart, trackEnd), 250)
  }

  finalConfetti = () => {
    confetti({
      particleCount: 75,
      spread: 35,
      gravity: 1,
      angle: 65,
      startVelocity: 45,
      ticks: 100,
      scalar: 1.2,
      disableForReducedMotion: true,
      origin: { x: 0, y: 1 }
    })

    confetti({
      particleCount: 75,
      spread: 35,
      gravity: 1,
      angle: 115,
      startVelocity: 45,
      ticks: 100,
      scalar: 1.2,
      disableForReducedMotion: true,
      origin: { x: 1, y: 1 }
    })
  }

  play = () => {
    return (
      <div className='cardsCarousel'>
        {
          this.state.cards.map((c, i) => {
            let style = this.state.currentCard === i ? ' cardActive' : ''
            style += i < this.state.currentCard ? ` ${this.state.discardType}` : ''
            return (
              <div key={i} className={`cardsCarouserCardWrapper${style}`}>
                <Card card={c} skip={this.skipCard} resolve={this.resolveCard} downvote={this.downvoteCard} />
              </div>
            )
          })
        }
        <div style={{ opacity: this.state.currentCard >= this.state.cards.length ? 1 : 0 }}
          className={`cardsDone${this.props.darkMode ? ' darkMode' : ''}`} >
          <h1>{lang.CARDS_END_TITLE}</h1>
          <div>{lang.CARDS_END_CONTENT}</div>
          <div className='openPickerContainer'>
            <button className={'rcl openPickerButton'} onClick={this.playAgain}>
              {lang.CARDS_PLAY_AGAIN}
            </button>
            <button className={'rcl rclSecondaryDark openPickerButton'} onClick={this.reset}>
              {lang.CARDS_CHANGE_CONTENT}
            </button>
          </div>
        </div>
        {this.state.currentCard >= this.state.cards.length && this.finalConfetti()}
      </div >
    )
  }

  render = () => (
    <div id={'cardsContainer'} className={`${this.props.darkMode ? 'darkModeBackground' : ''}`}>
      {(!this.state.contentId || this.state.pickTracks) && this.intro()}
      {this.state.contentId && !this.state.pickTracks && !this.state.cards && this.loading()}
      {this.state.contentId && this.state.cards && this.play()}
    </div>
  )
}

export default Cards