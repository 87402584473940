import config from "../config"
import session from '../utils/session.js'

const delCustomer = (cb) => {
  fetch(`${config.HTTP_PROTOCOL}://${config.RECITALEYES_LOGIN_URL}/delCustomer`,
    { headers: { "Authorization": `Bearer ${session.get().token}` } }
  )
    .then(res => cb(res.ok))
    .catch(() => cb(false))
}

export default delCustomer