import React from "react"
import lang from '../lang/es'
import { getLawList } from "../actions/law"

import Modal from './Modal'
import Spinner from "./Spinner"

import './LawSelector.css'

const dateToString = (date) => {
  return `${date.getDate()} de ${lang.MONTHS[date.getMonth()]} de ${date.getFullYear()}`
}

const dateConverter = (sd) => {
  const d = sd.toString()
  const df = new Date(`${d.slice(0, 4)}-${d.slice(4, 6)}-${d.slice(6, 8)}`)
  return dateToString(df)
}

class ListLaws extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: this.props.show,
      filter: '',
      message: null,
      lawList: null
    }
  }

  componentDidMount = () => {
    console.log(this.state.lawList)
    if (!this.state.lawList) getLawList((lawList) => this.setState({ lawList }))
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps && prevProps.show !== this.props.show) this.setState({ filter: '', message: null })
  }

  handleFilterChange = (event) => this.setState({ filter: event.target.value })

  lawLabel = (version, date) => {
    if (Number.isInteger(parseInt(version))) {
      // Consolidated
      return `${lang.LAW_SELECTOR_LABEL_CONSOLIDATED} ${dateConverter(version)}`
    } else {
      // Others (dof, hash, etc.)
      return lang.LAW_SELECTOR_LABEL_UPDATED.replace('%1', dateToString(new Date(date)))
    }
  }

  select = (lawId) => this.props.selected(lawId)

  render = () => (
    <Modal
      show={this.props.show}
      close={this.props.close}
      title={lang.LAW_PURCHASE_MODAL_TITLE}
    >
      {
        this.state.lawList ?
          <div className={'lawPurchaseContent'}>
            <ul className={'lawPurchaseList'}>
              {
                Object.keys(this.state.lawList).map(
                  (lawId, index) =>
                    this.state.lawList[lawId].name.toLowerCase().includes(this.state.filter.toLowerCase()) //filter
                      ?
                      <li key={index}>
                        <button className={'lawPurchaserLawListButton'} onClick={() => this.select(lawId)}>
                          <div className={'lawPurchaserLawName'}>{this.state.lawList[lawId].name}</div>
                          <div className={'lawPurchaserLawDate'}>
                            {this.lawLabel(this.state.lawList[lawId].ver, this.state.lawList[lawId].date)}
                          </div>
                        </button>
                      </li> : ''
                )
              }
              <li key={'requestLaw'} className={'lawPurchaserContact'}>
                ¿No encuentras alguna norma? ¡Pídenosla pulsando <button
                  className={'rcl rclSecondary lawPurchaserOpenContact'} onClick={this.props.toggleContact}>AQUÍ</button> y
                trataremos de tenerla disponible <strong>en menos de 72h</strong>!
              </li>
            </ul>
            <input
              className="cardInput lawPurchaseInputFilter"
              type="text"
              name="filter"
              placeholder={lang.LAW_PURCHASE_FILTER_PLACEHOLDER}
              value={this.state.filter}
              onChange={this.handleFilterChange}
            />
          </div>
          :
          <div className={'purchasedLawDone purchasedLawDoneSpinner'}>
            <Spinner className={'purchasedLawSpinner'} />
            <div className={'purchasedLawSpinnerText'}>{lang.LOADING}</div>
          </div>
      }
    </Modal>
  )
}

export default ListLaws