import React from "react"

import PlayerPlaylistLawContent from "./PlayerPlaylistLawContent"
import './PlayerPlaylistLaw.css'
import Spinner from "./Spinner"
import lang from "../lang/es"
import { getCustomerLaws, getLawList } from "../actions/law"
import LawSelector from "./LawSelector"

class PlayerPlaylistLaw extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showLawPurchaser: false,
      lawList: null,
      customerLaws: null,
      currentLaw: null
    }
  }

  componentDidMount = () => {
    if (!this.state.lawList) getLawList((lawList) => this.setState({ lawList }))
    if (!this.state.customerLaws) getCustomerLaws((customerLaws) => this.setState({ customerLaws }))
  }

  purchaseDone = () => {
    getCustomerLaws((customerLaws) => this.setState({ customerLaws }))
  }

  setLaw = (currentLaw) => {
    this.setState({ currentLaw })
  }

  unsetLaw = () => this.setState({ currentLaw: null })

  showLoading = () => {
    return (
      <div className={'playerPlaylistLoading'}>
        <Spinner className={'playerPlaylistLoadingSpinner'} />
        {lang.LOADING}
      </div>
    )
  }

  showCustomerLaws = () => {
    return (
      <div className={'playerPlaylistLawListContainer'}>
        <ul className={'playerPlaylistLawList'}>
          {
            this.state.customerLaws.map(
              ({ law }, index) =>
                <li key={index}>
                  <button className={'playerPlaylistLawLawButton'} onClick={() => this.setLaw(law)}>
                    {this.state.lawList[law].name}
                  </button>
                </li>
            )
          }
        </ul>
        <button className={'rcl playerPlaylistLawAddLawButton'} onClick={this.toggleShowLawPurchase}>
          {lang.PLAYLIST_LAW_ADD_LAW_BUTTON}
        </button>
      </div>
    )
  }

  showPlayer = () => {
    return (
      <PlayerPlaylistLawContent
        lawId={this.state.currentLaw}
        playTrack={this.props.playTrack}
        unsetLaw={this.unsetLaw}
      />
    )
  }

  toggleShowLawPurchase = () => this.setState({showLawPurchaser: !this.state.showLawPurchaser})

  render = () => (
    <div id={'playerPlaylistLawContainer'}>
      {!this.state.lawList || !this.state.customerLaws ?
        this.showLoading()
        :
        !this.state.currentLaw ?
          this.showCustomerLaws()
          :
          this.showPlayer()
      }
      <LawSelector
        show={this.state.showLawPurchaser}
        close={this.toggleShowLawPurchase}
        toggleContact={this.props.toggleContact}
        setConfirm={this.props.setConfirm}
        purchaseDone={this.purchaseDone}
      />
    </div>
  )
}

export default PlayerPlaylistLaw