import React from "react"

import PlayerPlaylistLaw from "./PlayerPlaylistLaw"
import PlayerPlaylistCustom from "./PlayerPlaylistCustom"
import './PlayerPlaylist.css'

class PlayerPlaylist extends React.Component {

  playlistContent = () => {
    if (this.props.discType === 'law') {
      return (
        <PlayerPlaylistLaw
          currentLaws={this.props.currentLaws}
          playTrack={this.props.playTrack}
          setConfirm={this.props.setConfirm}
          toggleContact={this.props.toggleContact}
        />
      )
    } else if (this.props.discType === 'custom') {
      return (
        <PlayerPlaylistCustom
          playTrack={this.props.playTrack}
          //toggleSubscription={this.props.toggleSubscription}
          setEditCustomContent={this.props.setEditCustomContent}
          setConfirm={this.props.setConfirm}
        />
      )
    }
  }
  render = () => (
    <div id={'playerPlaylistContainer'} className={this.props.show ? '' : 'playerPlaylistHide'}>
      {this.playlistContent()}
    </div>
  )
}

export default PlayerPlaylist