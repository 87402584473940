/*
  TODO:
    - Download pdf/epub
    - Remove selected law
    - Theme selector (?)
    - New FAQ
    - Unselect laws

  TODO BEFORE NEXT RELEASE:
    - Test retrier! (and actually everything...)
    - Fix playback icon appearing animation

  TODO:
    - Custom content FAQ/help/manual
    - Remove info text from forms and user toastify for better user experience
    - FORM IN THE FORMS!! https://www.chromium.org/developers/design-documents/create-amazing-password-forms
    - Aviso cookie(s)!
    - Improve animations with Anxo's recommendation or setTimeout AND remove components from UI

  TODO (improvements):
    - Email on subscription expiration
    - Move texts from ContactForm to lang files!
    - Custom playlist (skip some articles from a low / mix laws)
    - Let user determine witch consolidated version wants to play
    - When user changes playback speed, update timing bar (Sergio)
    - Poder apuntar notas
    - Diccionario Panhispánico del Español Jurídico (https://dpej.rae.es/)

  TODO server:

 */
import React from 'react'

import cookieManager from './utils/cookieManager'
import validateToken from './utils/validateToken.js'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import lang from './lang/es'
import config from './config.js'

import PasswordRestore from './components/PasswordRestore'
import UpdatePassword from './components/UpdatePassword'
import Login from './components/Login'
import SignUp from "./components/SignUp"
import NavBar from './components/NavBar'
import Menu from './components/Menu'
import Player from './components/Player'
import ContactForm from "./components/ContactForm"
import CustomContentEditor from "./components/CustomContentEditor"
import Confirm from "./components/Confirm"
//import Subscription from "./components/Subscription"
import Prompt from "./components/Prompt"
import SessionRenovator from "./utils/SessionRenovator.js"
import MyAccount from './components/MyAccount'
import Cards from './components/Cards.js'

//Capacitor Android/iOS URL handler:
import { Capacitor } from '@capacitor/core';
import { App as CapacitorApp } from '@capacitor/app'
import { EdgeToEdge } from '@capawesome/capacitor-android-edge-to-edge-support'
import { StatusBar, Style } from '@capacitor/status-bar'

//iOS webapp hack for background audio:
import IOsBackgroundAudioComponent from './components/IOsBackgroundAudioComponent'

import './Themes.css'
import './App.css'

const iOS = navigator.platform === 'iPhone' || navigator.platform === 'iPad'

const getUpdatePasswordToken = (url) => {
  const hash = url.toString().split('?')
  const passToken = (hash.length === 2 && hash[0].endsWith('#updatePassword')) ?
    hash[1].replace('passToken=', '') :
    false
  return passToken
}

class App extends React.Component {
  constructor(props) {
    super(props)

    const hash = window.location.hash.split('?')
    const passToken = getUpdatePasswordToken(window.location)


    /**************/
    /* Demo login */
    /**************/
    /*if (window.location.hash === '#dmlog') {
      this.setAuthToken(
        'eyJ...',
        true
      )
    }*/
    /**************/

    this.state = {
      discType: null,
      showLogin: false,
      showMenu: false,
      showMenuFullScreen: true,
      showSignUp: (hash[0] === '#signUp'),
      showPlaylist: false,
      showPasswordRestore: (hash[0] === '#passwordRestore'), // For better customer support
      showUpdatePassword: passToken,
      showContact: false,
      showAccount: false,
      //showSubscription: null,
      confirm: null,
      editCustomContent: null,
      currentUser: '',
      currentLaws: [],
      canPlay: false,

      theme: 'chocoleyes',
      darkMode: (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches),

      iOsBkgEnabled: false
    }

    this.sessionRenovator = new SessionRenovator()
  }

  componentDidMount = () => {
    if (Capacitor.isNativePlatform()) this.capacitorAppSetup()
    this.getAuthToken(true)
  }

  cancelUpdatePassword = () => {
    window.location.hash = ''
    this.setState({ showUpdatePassword: false })
  }

  capacitorAppSetup = async () => {
    CapacitorApp.addListener('appUrlOpen', (event) => {
      // Example url: https://app.recitaleyes.com/#updatePassword?passToken=abc...
      if (event.url.startsWith(`https://${config.RECITALEYES_APP_URL}/#updatePassword`)) {
        const passToken = getUpdatePasswordToken(event.url)
        this.setState({
          showSignUp: false,            // Disable signup screen
          showPasswordRestore: false,   // Just in case...
          showUpdatePassword: passToken
        })
      }
    })

    await EdgeToEdge.setBackgroundColor({ color: '#441d02' })
    await StatusBar.setStyle({ style: Style.Light })
    //await StatusBar.setOverlaysWebView({ overlay: false })
    //await StatusBar.setBackgroundColor({ color: '#441d02' })
  }

  setDiscType = (discType) => this.setState({
    discType,
    showMenuFullScreen: false,
    showPlaylist: true,
    iOsBkgEnabled: true
  })

  setAuthToken = (tokenCookie, isLogin) => {
    if (tokenCookie) { // tokenCookie is null when login attempt failed
      cookieManager.set(tokenCookie)
      this.getAuthToken(isLogin)
    } else {
      if (isLogin) this.delAuthToken() // Do not delete cookie if token renovation fails (connection, etc.)
      else { // ... instead, just check if current cookie still active and only if not call delAuthToken
        if (!cookieManager.get()) this.delAuthToken()
      }
    }
  }

  loginHomeState = (tokenValues) => {
    // Help password managers to understand that logging was successful
    window.location.hash = '#signed-in'
    return {
      discType: null,
      showLogin: false,
      showMenu: true,
      showMenuFullScreen: true,
      showSignUp: false,
      showPlaylist: false,
      showPasswordRestore: false,
      showUpdatePassword: false,
      showContact: false,
      showAccount: false,
      //showSubscription: false,
      confirm: null,
      editCustomContent: null,
      user: tokenValues,
      currentUser: tokenValues.email,
      currentLaws: [],
      canPlay: true,
      iOsBkgEnabled: false
    }
  }

  authReloadState = (tokenValues) => {
    return {
      showLogin: false,
      user: tokenValues,
      currentUser: tokenValues.email
    }
  }

  getAuthToken = (isLogin) => {
    const token = cookieManager.get()
    const tokenValues = validateToken(token)

    if (tokenValues) {
      const nextState = isLogin ? this.loginHomeState(tokenValues) : this.authReloadState(tokenValues)

      this.setState(nextState)

      this.sessionRenovator.start(this.setAuthToken)
    } else {
      this.delAuthToken()
    }
  }

  delAuthToken = () => {
    cookieManager.del()
    this.setState({
      discType: null,
      showLogin: true,
      showMenu: false,
      showMenuFullScreen: true,
      showPlaylist: false,
      showContact: false,
      showAccount: false,
      //showSubscription: null,
      editCustomContent: null,
      currentUser: '',
      currentLaws: [],
      canPlay: false
    })

    window.location.hash = ''

    this.sessionRenovator.stop()
  }

  setConfirm = (message, cb) =>
    this.setState({ confirm: cb ? { message, cb } : null })
  setPrompt = (message, hint, cb) =>
    this.setState({ prompt: cb ? { message, hint, cb } : null })

  togglePasswordRestore = () => this.setState({ showPasswordRestore: !this.state.showPasswordRestore })
  toggleMenu = () => this.setState({ showMenu: !this.state.showMenu })
  togglePlaylist = (show) => this.setState({ showPlaylist: show })
  //toggleSubscription = () => this.setState({showSubscription: !this.state.showSubscription})

  toggleContact = () =>
    this.setState({ showContact: !this.state.showContact })
  toggleSignUp = () =>
    this.setState({ showSignUp: !this.state.showSignUp })
  toggleAccount = () =>
    this.setState({ showAccount: !this.state.showAccount })

  toggleDarkMode = () =>
    this.setState({ darkMode: !this.state.darkMode })

  setEditCustomContent = (contentId, cb) =>
    this.setState({ editCustomContent: cb ? { contentId, cb } : null })

  toggleIOsBackgroundAudio = (notify) => {
    if (notify) toast.info(this.state.iOsBkgEnabled ? lang.iOsBkgDisabled : lang.iOsBkgEnabled, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });

    this.setState({ iOsBkgEnabled: !this.state.iOsBkgEnabled })
  }

  render = () => (
    <div className={`App ${this.state.theme}`}>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {iOS ? <IOsBackgroundAudioComponent enabled={this.state.iOsBkgEnabled} /> : ''}
      <UpdatePassword
        updatePasswordToken={this.state.showUpdatePassword}
        cancelUpdatePassword={this.cancelUpdatePassword}
        delAuthToken={this.delAuthToken}
      />
      <MyAccount
        show={this.state.showAccount}
        togglePasswordRestore={this.togglePasswordRestore}
        currentUser={this.state.currentUser}
        setConfirm={this.setConfirm}
        close={this.toggleAccount}
      />
      <PasswordRestore
        show={this.state.showPasswordRestore}
        togglePasswordRestore={this.togglePasswordRestore}
        currentUser={this.state.currentUser}
      />
      <Login
        show={this.state.showLogin}
        setAuthToken={this.setAuthToken}
        toggleSignUp={this.toggleSignUp}
        togglePasswordRestore={this.togglePasswordRestore}
      />
      <SignUp
        show={this.state.showSignUp}
        toggleSignUp={this.toggleSignUp}
      />
      <NavBar
        toggleMenu={this.toggleMenu}
        showMenu={this.state.showMenu}
        togglePlaylist={this.togglePlaylist}
        showPlaylist={this.state.showPlaylist}
        showPlaylistButton={this.state.discType === 'law' || this.state.discType === 'custom'}
      />
      <Menu
        delAuthToken={this.delAuthToken}
        show={this.state.showMenu}
        fullScreen={this.state.showMenuFullScreen}
        toggleMenu={this.toggleMenu}
        setDiscType={this.setDiscType}
        setConfirm={this.setConfirm}
        toggleAccount={this.toggleAccount}
        toggleDarkMode={this.toggleDarkMode}
        toggleContact={this.toggleContact}
      //toggleSubscription={ this.toggleSubscription }
      />
      {this.state.discType === 'cards' &&
        <Cards
          darkMode={this.state.darkMode}
          toggleContact={this.toggleContact}
          setConfirm={this.setConfirm}
        />
      }
      {(this.state.discType === 'law' || this.state.discType === 'custom') &&
        <Player
          showPlaylist={this.state.showPlaylist}
          togglePlaylist={this.togglePlaylist}
          discType={this.state.discType}
          currentLaws={this.state.currentLaws}
          canPlay={this.state.canPlay}
          currentUser={this.state.currentUser}
          setEditCustomContent={this.setEditCustomContent}
          setConfirm={this.setConfirm}
          toggleContact={this.toggleContact}
          darkMode={this.state.darkMode}
        />
      }
      <ContactForm
        show={this.state.showContact}
        from={this.state.currentUser}
        optionalFrom={true}
        close={this.toggleContact}
      />
      {this.state.editCustomContent ?
        <CustomContentEditor
          editCustomContent={this.state.editCustomContent}
          close={() => this.setEditCustomContent()}
        />
        : ''
      }
      <Confirm
        message={this.state.confirm?.message}
        cb={this.state.confirm?.cb}
        closeConfirm={() => this.setConfirm()}
      />
      <Prompt
        message={this.state.prompt?.message}
        hint={this.state.prompt?.hint}
        cb={this.state.prompt?.cb}
        closePrompt={() => this.setPrompt()}
      />
      {/* Subscription section temporarily disabled
      <Subscription
        show={this.state.showSubscription}
        currentUser={this.state.currentUser}
        setAuthToken={this.setAuthToken}
        setPrompt={ this.setPrompt }
        close={ this.toggleSubscription }
      />
      */}
    </div>
  )
}

export default App
