import React from "react"

import config from '../config.js'
import PlayerAudio from './PlayerAudio.js'
import PlayerText from './PlayerText.js'
import PlayerPdfViewer from "./PlayerPdfViewer"
import PlayerReport from "./PlayerReport"
import PlayerPlaylist from "./PlayerPlaylist"

import './Player.css'

class Player extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      playbackRate: 1.0,
      discId: null,
      title: null,
      html: null,
      pdf: null,
      audioUrl: '',
      navUrl: '',

      next: null,
      prev: null,
      retry: null,
      startPoint: 0,

      retries: 0
    }
  }

  playbackRateControl = (increment) => {
    const newRate = (this.state.playbackRate * 10 + increment * 10) / 10
    if (newRate >= config.MIN_PLAYBACK_RATE && newRate <= config.MAX_PLAYBACK_RATE)
      this.setState({ playbackRate: newRate })
  }

  setTrack = (playable, startPoint) => {
    this.setState({
      discId: playable.discId,
      track: playable.track,
      title: playable.title,
      subtitle: playable.subtitle,
      html: playable.html,
      pdf: playable.pdf,
      audioUrl: playable.audioUrl,
      navUrl: playable.navUrl,
      next: playable.next,
      prev: playable.prev,
      retry: playable.retry,
      startPoint,
    })

    // Force history write so browsers can mark the link in the playlist as visited even on
    //  automatic audio change ("next/previous track" button and end of audio).
    // eslint-disable-next-line no-restricted-globals
    history.pushState(null, null, this.state.navUrl)

    this.props.togglePlaylist(false)
  }

  retryTrack = (startPlayFrom) => {
    if (this.state.retries < config.MAX_AUDIO_RETRIES) {
      // If we are retrying the same point, consume one retry. If not, set retries to 0.
      this.setState({
        retries: this.state.startPoint === startPlayFrom ? this.state.retries + 1 : 0
      }
      )
      // Ask the playlist to send a new audio link
      this.state.retry(startPlayFrom)
    }
  }

  render = () => (
    <div id={"lawPlayerContainer"} className={`lawPlayerContainer${this.props.darkMode ? ' darkModeBackground' : ''}`}>
      {this.state.html ?
        <PlayerText
          title={this.state.title}
          subtitle={this.state.subtitle}
          html={this.state.html}
          showPlaceholder={(this.state.title || this.state.html)}
          className={this.props.darkMode ? 'darkMode' : ''}
        />
        : ''
      }
      {this.state.pdf ?
        <PlayerPdfViewer
          pdf={this.state.pdf}
          className={this.props.darkMode ? 'darkMode' : ''}
        />
        : ''
      }
      {this.props.discType && this.state.audioUrl ?
        <PlayerAudio
          src={this.state.audioUrl}
          playbackRateControl={this.playbackRateControl}
          playbackRate={this.state.playbackRate}
          prev={this.state.prev}
          next={this.state.next}
          canPlay={this.props.canPlay}
          startPoint={this.state.startPoint}
          retryTrack={this.retryTrack}
          //toggleSubscription={this.props.toggleSubscription}
          className={this.props.darkMode ? 'darkMode' : ''}
        /> : ''
      }
      {this.props.discType ?
        <PlayerPlaylist
          discType={this.props.discType}
          currentLaws={this.props.currentLaws}
          playTrack={this.setTrack}
          show={this.props.showPlaylist}
          setEditCustomContent={this.props.setEditCustomContent}
          setConfirm={this.props.setConfirm}
          toggleContact={this.props.toggleContact}
        /> : ''
      }
      {this.props.discType ?
        <PlayerReport
          currentUser={this.props.currentUser}
          discType={this.props.discType}
          discId={this.state.discId}
          track={this.state.track}
          setConfirm={this.props.setConfirm}
          className={this.props.darkMode ? 'darkMode' : ''}
        /> : ''
      }
    </div>
  )
}

export default Player