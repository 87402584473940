import config from '../config.js'
import session from '../utils/session.js'

const getCards = (contentType, contentId, trackStart, trackEnd, cb) => {
  const url = `${config.HTTP_PROTOCOL}://${config.RECITALEYES_API_URL}/${contentType}/${contentId}/cards/${trackStart}/${trackEnd}`
  fetch(url,
    { headers: { "Authorization": `Bearer ${session.get().token}` } }
  )
    .then(res => res.json())
    .then(cards => cb(cards))
}

const reportCard = (contentType, contentId, card, cb) => {
  const url = `${config.HTTP_PROTOCOL}://${config.RECITALEYES_API_URL}/${contentType}/${contentId}/cards/report`
  fetch(url,
    {
      headers: { "Authorization": `Bearer ${session.get().token}` },
      method: 'post',
      body: JSON.stringify(card)
    }
  )
    .then(res => cb(res.status === 200))
}

export { getCards, reportCard }